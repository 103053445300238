<template>
  <div class="container mt-3">
    <div class="mt-3" v-if="course">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/"><i class="fas fa-home"></i></router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/public-courses/courses">Kursus</router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ course.attributes.course_name }}
          </li>
        </ol>
      </nav>
    </div>

    <div class="mt-5" v-if="course">
      <div class="mx-2">
        <h3 class="fw-bold">{{ course.attributes.course_name }}</h3>
      </div>
    </div>

    <div class="container-box mt-3">
      <div v-if="topics.length > 0" class="row mx-0 g-2">
        <div
          class="col-sm-12 col-md-12"
        >
          <div class="header-box">
            <div class="row mx-0">
              <div class="col-8">
                <div class="box-left">
                  <h1>Selamat Datang.</h1>
                  <h5>
                    Teruskan mempelajari ilmu berkaitan ibadat Haji!
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contents mt-3">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              href="#"
              class="nav-link active"
              >Utama</a
            >
          </li>
        </ul>

        <div class="nav-contents mt-3">
          <div class="row mx-0 g-2">
            <div
              class="col-12"
              v-for="topic in filteredTopics"
              :key="topic.id"
            >
              <div
                class="topic-box position-relative"
                :class="{ 'topic-box-blue' : true }"
                @click="goto(`/public-courses/topics/${topic.id}`, topic)"
              >
                <div class="box-featured-img" :style="topicBoxBg()"></div>
                <div class="topic-box-inner position-relative p-3">
                  <div class="row mx-0">
                    <div class="col-6 col-sm-8 col-md-10">
                      <div class="title">
                        <span
                          v-if="topic.attributes.topic_series"
                          class="d-block mb-1"
                          >Siri {{ topic.attributes.topic_series }}</span
                        >
                        <h5>{{ topic.attributes.topic_name }}</h5>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="d-flex justify-content-end align-items-center h-100">
                        <span class="span-click"
                          >Klik untuk masuk <i class="fas fa-arrow-right"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div v-if="filteredTopics.length <= 0" class="nav-contents mt-3">
              <i>Tiada.</i>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../utils/API";

export default {
  data() {
    return {
      course: null,
      topics: [],
      filteredTopics: [],
    };
  },
  methods: {
    async goto(_path, _topic) {
      this.$router.push(_path);
    },
    topicBoxBg() {
      let randomNumber = Math.ceil(Math.random() * 4);
      return `
                background-image: url(/img/random/featured-img-${randomNumber}.jpg);
                background-size: cover;
                background-repeat: no-repeat;
                `;
    },
    
    
  },
  async mounted() {
    let self = this;
    window.addEventListener("resize", () => {
      const bg = document.getElementById("certBG");
      self.$nextTick(() => {
        self.certWidth = bg.clientWidth;
      });
    });
    const res = await API.get(
      `courses/${this.$route.params.courseid}?populate[0]=topics`
    );
    this.course = res.data.data;
    this.topics = res.data.data.attributes.topics.data;
    //sort topics
    this.topics = this.topics.sort((itemA, itemB) => {
      let x = itemA.attributes.topic_series.toLowerCase();
      let y = itemB.attributes.topic_series.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.filteredTopics = this.topics;
  },
};
</script>